module.exports = {
  thumbnail: {
    type: "image",
    value: null,
    value_local: null,
    meta: {
      alt: null,
      redirect_link: null
    }
  },
  material: null,
  title: null
};
