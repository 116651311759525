<template>
  <div>
    <page-breadcrumb title="Lesson" class="mb-3" :items="breadcrumbItems" />
    <data-create-popup title="Create Lesson"
      :schema="create_schema" :default-data="JSON.parse(JSON.stringify(default_data))"
      :create-data-fn="createItem" @create="getList"
    />
    <b-row class="mt-3" v-if="lessons">
      <b-col v-for="lesson in lessons" :key="lesson._id" cols="3">
        <lesson-card :lesson="lesson"/>
      </b-col>
    </b-row>
    <b-pagination class="mt-auto" pills size="lg" align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
  </div>
</template>
<script>
import LessonCard from './_components/LessonCard.vue'
import service from '../service'
import default_data from '../default_data'
const create_schema = [
  {
    cols: 4,
    fields: [
      { label: 'Thumbnail', field: 'thumbnail', input_type: 'ui-component' },
    ]
  },
  {
    cols: 8,
    fields: [
      { label: 'Lesson Title', field: 'title', validate: { required: true } },
      { label: 'Native 1-1 - Lesson Materials', field: 'material' },
    ]
  }
]
export default {
  components: {
    LessonCard
  },
  data(){
    return {
      lessons: [],
      current_page: 1,
      total_items: 0,
      items_perpage: 10,
      create_schema,
      default_data
    }
  },
  watch: {
    current_page() {
      this.getList()
    },
  },
  computed: {
    courseId() {
      return this.$route.params.courseId;
    },
    breadcrumbItems() {
      let items = [
        {
          text: 'Course',
          to: { name: 'courses-detail', params: {id: this.courseId} },
        },
      ]
      return items
    },
  },
  created(){
    this.getList();
  },
  methods: {
    async getList() {
      let result = await service.getList({
        page: this.current_page,
        limit: this.items_perpage,
        query: encodeURIComponent(JSON.stringify({ course_id: this.courseId })),
        order_by: 'display_order',
        order: 1
      })
      this.lessons = result.list
      this.total_items = result.total
    },
    async createItem(data) {
      let new_item = await service.create({ ...data, course_id: this.courseId, display_order: this.total_items });
      return new_item;
    },
    toDetailPage(){
      this.$route.push({ })
    }
  }
}
</script>